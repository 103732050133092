type menuUrls = {
	label: string;
	en_label?: string;
	to: string;
};

export const urlMenuList: menuUrls[] = [
	{
		label: "TOPICS",
		to: "/topics/",
	},
	{
		label: "LOGIN",
		to: "login",
	},
];

export const urlFooterMenuList1 = () => [
	{
		label: "TOPICS",
		to: "/topics/",
	},
	{
		label: "ログイン",
		// to: `${process.env.GATSBY_MODD_URL_LOGIN}https://ssj-shop.net`
		to: "login",
	},
	{
		label: "新規会員登録",
		to: `${process.env.GATSBY_MODD_URL_REGISTER}`,
	},
];

export const urlFooterMenuList2 = () => [
	{
		label: "ALL",
		to: "/about/",
	},
	{
		label: "S.S.J 2024",
		to: "/about/",
	},
	{
		label: "S.S.J REGULAR",
		to: "/about/",
	},
];

export const urlFooterMenuList3 = () => [
	{
		label: "利用規約",
		to: `${process.env.GATSBY_MODD_URL_TERM}`,
	},
	{
		label: "FAQ",
		to: `${process.env.GATSBY_MODD_URL_HELP}`,
	},
	{
		label: "お問い合わせ",
		to: `${process.env.GATSBY_MODD_URL_CONTACT}`,
	},
	{
		label: "プライバシーポリシー",
		to: `${process.env.GATSBY_MODD_URL_PRIVACY}`,
	},
	{
		label: "特定商取引法に基づく表示",
		to: `${process.env.GATSBY_MODD_URL_TOKUTEI}`,
	},
	{
		label: "志尊淳オフィシャルサイト - JUN SHISON OFFICIAL SITE",
		to: "https://ssj.net",
	},
	{
		label: "志尊淳オフィシャルファンクラブ「S.S.J」",
		to: "https://ssj.net/about",
	},
];

export const urlFcMenuList: menuUrls[] = [
	{
		label: "TOPICS",
		to: "/topics/",
	},
	{
		label: "CART",
		to: `${process.env.GATSBY_MODD_API_CART}`,
	},
	// {
	//     label: "LOGOUT",
	//     // to: `${process.env.GATSBY_MODD_URL_LOGIN}https://ssj-shop.net`
	//     to: 'logout'
	// }
];

export const urlFcFooterMenuList = () => [
	{
		label: "TOPICS",
		to: "/topics/",
	},
	{
		label: "カート",
		to: `${process.env.GATSBY_MODD_API_CART}`,
	},
	// {
	//     label: "ログアウト",
	//     // to: `${process.env.GATSBY_MODD_URL_LOGIN}https://ssj-shop.net`
	//     to: 'logout'
	// }
];

export const urlSns = {
	twitter: `${process.env.GATSBY_SNS_URL_TWITTER}`,
	instagram: `${process.env.GATSBY_SNS_URL_INSTAGRAM}`,
};
